import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from './HomePage/HomePage';
import { Navbar } from './Navbar/NavBar';
import About from './About/ABout';
import Projects from './PROJECTS/Projects';
import Contact from './Contact/Contact';
import Blogs from './Blogs/Blogs';
import Footer from './Footer/Footer';
import Gallery from './Gallery/Gallery';
import ScrollTop from './scrolltop/Scrolltop';
import ProjectDetail from './PROJECTS/ProjectDetail';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
     <Router>
      <ScrollTop/>
      <Navbar/>    
      <Switch>
        <Route exact path="/" component={HomePage} />        
        <Route path="/About-womeki-investors-club" component={About} />
        <Route path="/Projects" component={Projects} />
        <Route path="/Contact" component={Contact} />
        <Route path="/Blogs" component={Blogs} />
        <Route path="/Gallery" component={Gallery} />
        <Route path="/product/:_id" component={ProjectDetail} />
      </Switch> 
      <Footer/>
     </Router>
    </div>
  );
}

export default App;
