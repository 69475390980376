import React, { useState, useEffect, useRef } from 'react';
import './ValidationForm.css';
import emailjs from '@emailjs/browser';

const ValidationForm = () => {
  const [isFormVisible, setFormVisibility] = useState(false);
  const [nameInput, setNameInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFormVisibility(true);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, []);

  const closeForm = () => {
    setFormVisibility(false);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_jvoxnkp', 'template_r1aqy12', form.current, 'vpigVXDjQF8Z0u1eF')
      .then((result) => {
        console.log(result.text);
        form.current.reset();
        setNameInput('');
        setPhoneInput('');
      }, (error) => {
        console.log(error.text);
      });
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    if (/^[A-Za-z\s]+$/.test(inputValue) || inputValue === '') {
      setNameInput(inputValue);
    }
  };
  const handleNumberChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, '');
    const limitedNumericValue = numericValue.slice(0, 10);
    setPhoneInput(limitedNumericValue); 
  };
  return (
    <div className={`overlay ${isFormVisible ? 'visible' : 'hidden'}`}>
      <div className='container valid-form-main-div'>
        <div className='row justify-content-center'>
          <div className='col-md-6 col-sm-12'>
            <form  ref={form} className='valid-form' onSubmit={sendEmail} >
              <button className="close-icon" onClick={closeForm}>
                X
              </button>
              <img className='wic-form-logo' src='/logo.webp' alt='logo'/>
              <h4 className='form-title mt-lg-5 mt-md-4 '>Questions About Your Next Property Move? Ask Away with Womeki Investors Club - Your Queries, Our Expertise!</h4>
              <div className="form-group valid mt-lg-5 mt-md-3">
                <input type="name" className="form-control border-0 form-input" name='to_name' placeholder="Name" value={nameInput}  onChange={handleNameChange}/>
              </div>
              <div className="form-group  valid mt-lg-5 mt-md-3">
                <input type="tel" className="form-control border-0 form-input" name='message' placeholder="Phone" value={phoneInput}   onChange={handleNumberChange}/>
              </div>
              <div className="form-group  valid mt-lg-5 mt-md-3">
                <input type="email" className="form-control border-0 form-input" name='from_name' placeholder="Enter email"/>
              </div>
              <div className="form-check mt-lg-5 mt-md-3">
                <input className="form-check-input checkbox-btn" type="checkbox" value="" id="defaultCheck1"/>
                <label className="form-check-label checkbox-text" htmlFor="defaultCheck1">
                  I am ready to be connected with Womeki Investors Club in WhatsApp, Email, Call etc.
                </label>
              </div>
              <button type="submit" value="Send" className=" btn-valid-form mt-4">SUBMIT</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidationForm;
