import React, { useState, useEffect } from 'react';
import "./Exclusive.css";
import { Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faMoneyCheckAlt  } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Link } from 'react-router-dom';
const ExcliusivePrijects = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    axios.get(`https://apis.womekiinvestorsclub.co.in/createpost`)
    .then(response => {
      setPosts(response.data);
    })
    .catch(error => console.error('Error fetching posts:', error));
}, []);
  return (
    <div className=" container">
       <h2 className="heading-verticals"  >EXCLUSIVE PROJECTS</h2>
      <div className=" row ">
        <Swiper className=" main-exclusive"
          modules={[Navigation,  Autoplay]}
          spaceBetween={8}
          slidesPerView={1}
          navigation
          loop={true}
          speed={1500}
        autoplay={{
          delay: 3100,
          disableOnInteraction: false,
        }}
        >
          {posts
                .filter(post =>post.category === "WIC" &&  post.subcategory === "Exclusive")
                .map(post => (
              <SwiperSlide>
            <div className="d-flex align-items-center justify-content-center">
                <div className=" Exclusive-Landing ">
                  <img
                  src={`https://apis.womekiinvestorsclub.co.in/${post.files}`}
                    alt="ll"
                    className=" Exclusive-bg "
                  />
                  <div className=" exclusive-overlay ">
                 
                      <div className=" Exclusive-detaibox " >
                        <h3 className=" detail-exclusive-heading" >{post.projectname}</h3>
                        <h3 className=" detail-exclusive-heading-location ">{post.address}
                        <FontAwesomeIcon className="card-location-icon" icon={faLocationDot} /></h3>
                        <h3 className=" detail-exclusive-price" >{post.price}
                        <FontAwesomeIcon className="card-currency-icon ms-2" icon={faMoneyCheckAlt} /></h3>
                        <Link to={`/product/${post._id}`}>
                        <p className="Button-Exclusive" >See Details</p></Link>
                      </div>
                    </div>
                    <div>
                    </div>
                  </div>
                  </div>
            </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ExcliusivePrijects;