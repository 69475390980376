import React from "react";
import "./About.css";
import Ourleadership from "../HomePage/Ourleadership/Ourleadership";

const ABout = () => {
  return (
    <div className="container">
      <h1 className="heading-verticals">ABOUT US</h1>
      <div className="row ">
        <div className="col-12 about-ceo-cont-div1">
          <div className="gauravsir-img-div">
          <img className="gauravsirImage" src="./Gauravsir.webp" alt="ss" />
          </div>
          <img className="sec1first" src="./sec1first.webp" alt="ss" />
          <div className="about-ceo-cont-div2 p-sm-3">
          <p className="aboutusContent">
            Womeki Investors Club (WIC), a premier real estate force in India
            since 2021, specializes in the acquisition and sale of residential
            projects, hotels, farmhouses, plots, and prime commercial spaces in
            Delhi NCR and other major cities. Our team, composed of seasoned
            professionals with extensive experience in sales and investment,
            combines corporate acumen with real estate backgrounds to offer a
            uniquely professional and systematic approach to client service.
            Embracing a belief in constant innovation and pushing boundaries,
            WIC not only navigates the real estate landscape but actively shapes
            it, propelling us forward into new and exciting territories.
          </p>
        </div>
        </div>
      </div>
      <h4 className="heading-verticals "> OUR HISTORY</h4>
      <div className="row">
        <div className="col-md-5 col-sm-12 ">
          <div className="ourhis-img-div">
          <img className="ourhis-img" src="./ourhis1st.webp" alt="his" />
          </div>
         
        </div>
        <div className="col-md-7 col-sm-12 d-flex align-items-center justify-content-center">
          <p className="ourhis-cont">
            The Tonger family, with an entrepreneurial background, has
            successfully managed two schools since 1988. Mr. Gaurav Tonger's
            involvement in running an NGO has equipped him with invaluable
            experience and a determined spirit for business success.
          </p>
        </div>
        </div> 
      <div className="row d-flex flex-column-reverse flex-md-row mt-5">
        <div className="col-md-7 col-sm-12 d-flex align-items-center justify-content-center">
            <p className="ourhis-cont ">
              For an impressive 36 years, the Tonger Family has played a vital
              role in enhancing the nation's education landscape through their
              esteemed institution, MC Gopichand Group of Institutions. Their
              unwavering commitment is evident in providing accessible,
              high-quality education that enriches minds without burdening
              finances. This enduring legacy underscores their dedication to
              shaping brighter futures, positioning education as a guiding light
              of empowerment and progress for the entire community.
            </p>
          </div>
          <div className="col-md-5 col-sm-12">
          <div className="ourhis-img-div">
            <img className="ourhis-img " src="./ourhis2nd.webp" alt="off" />
          </div>
          </div>
          </div>
       <div className="row  mt-5">
          <div className="col-md-5 col-sm-12">
            <div className="ourhis-img-div">
            <img className="ourhis-img " src="./ourhis3rd.webp" alt="ss" />
            </div>
          </div>
          <div className="col-md-7 col-sm-12 d-flex align-items-center justify-content-center">
            <p className="ourhis-cont">
              Simultaneously, Mr. Gaurav Tonger has translated this visionary
              approach into the real estate market. Building on his family's
              legacy, he seamlessly extends their commitment to excellence into
              a flourishing real estate venture. Prioritising quality,
              affordability, and community impact, Mr. Gaurav Tonger embodies
              the values instilled by his family, creating not just living
              spaces but nurturing environments where individuals can thrive and
              prosper.
            </p>
          </div>
          </div>
        
        <h4 className="heading-verticals mt-5 pt-5">WHAT WE OFFER</h4>
        <div className="row">
          <div className="col-md-6 col-lg-4 col-sm-12 d-flex justify-content-center">
            <div className="card border-0 card-offer-div ">
              <div className="offer-img-div">
              <img className="offer-img" src="/card1img.webp" alt="off" />
              </div>
              <div className="card-body offer-card-text">
                <p className="abt-offer-card-cont mt-2">
                  At the heart of our vision is an unwavering commitment to
                  clients, especially in the field of education. For 36 years,
                  we've passionately served through the esteemed MC Gopichand
                  Group of Institutions, providing accessible, high-quality
                  education without imposing financial burdens. Our enduring
                  legacy underscores a dedication to shaping brighter futures,
                  positioning education as a guiding light of empowerment and
                  progress for the entire community.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-sm-12 d-flex justify-content-center ">
            <div className="card border-0 card-offer-div">
            <div className="offer-img-div">
              <img className="offer-img" src="/card2img.webp" alt="off" />
              </div>
              <div className="card-body offer-card-text">
                <p className="abt-offer-card-cont">
                  Our core vision centers on fulfilling client commitments,
                  currently focusing on the dynamic realm of real estate.
                  Specializing in connecting clients with dream properties, be
                  it for business expansion, creative endeavors, or a
                  comfortable home, our seasoned team is dedicated to finding
                  the perfect match for unique preferences. With years of
                  experience, we provide expert investment consultancy, guiding
                  clients through market trends and opportunities for maximum
                  returns on their investments.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-sm-12 d-flex justify-content-center">
            <div className="card border-0 card-offer-div">
            <div className="offer-img-div">
              <img className="offer-img" src="/card3img.webp" alt="off" />
              </div>
              <div className="card-body offer-card-text">
                <p className="abt-offer-card-cont">
                  In our commitment to a holistic real estate approach, we now
                  offer cutting-edge IT solutions. Our skilled IT professionals
                  assist clients in setting up and optimizing businesses,
                  leveraging technology to enhance efficiency and productivity.
                  As we grow, we're driven to expand this vision into different
                  sectors, dedicated to serving clients with expertise and
                  innovation.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
        <div className="row mt-5">
        <div className="col-md-4 col-sm-12 d-flex align-items-center justify-content-center">
            <div className="card border-0 abt-inno-card mt-5">
              <h4 className="abt-card-num">01</h4>
              <h4 className="abt-inno-title">INNOVATIVE</h4>
              <p className="abt-inno-text">
                At the heart of our ethos is an unwavering commitment to
                innovation, driving excellence across education, real estate,
                and IT services.
              </p>
            </div>
            </div>
            <div className="col-md-4 col-sm-12 d-flex align-items-center justify-content-center">
            <div className="card  border-0 abt-inno-card mt-5 ">
              <h4 className="abt-card-num">02</h4>
              <h4 className="abt-inno-title">INTUITIVE</h4>
              <p className="abt-inno-text">
                Guided by an intuitive approach, we navigate the realms of real
                estate crafting solutions that anticipate and meet our clients'
                evolving needs seamlessly.
              </p>
            </div>
            </div>
            <div className="col-md-4 col-sm-12 d-flex align-items-center justify-content-center">
            <div className="card  border-0 abt-inno-card mt-5 ">
              <h4 className="abt-card-num">03</h4>
              <h4 className="abt-inno-title">MODERN</h4>
              <p className="abt-inno-text">
                Immersing ourselves in cutting-edge technologies, we sculpt
                extraordinary, modern experiences for our clients, setting the
                stage for a revolution in service excellence.
              </p>
            </div>
            </div>
          
        </div>
        </div>
      <Ourleadership/>
        <div className="row mt-5 justify-content-center">
          <div className="col-12 abt-contactus">
            <h4 className="callforus">CALL FOR OUR BEST ADVICE</h4>
            <img className="cimg ms-3 me-3" src="/callimg.webp" alt="hh" />
            <h4 className="callno">+91- 9911140024</h4>
          </div>
        </div>
      </div>
   
  );
};
export default ABout;